import React from 'react'
import '../CSS/CalculatorButton.css'
import {Link} from 'react-router-dom'

function CalculatorButton() {
    return (
        <Link to="/solarcalculator" className="calculator-button-container" >
        
        <i class="material-icons calculator-icon">computer</i>
       <h5 className="calculator-button-container-header" >Calculator</h5>
      
        </Link>
    )
}

export default CalculatorButton
