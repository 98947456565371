import React from 'react'
import '../CSS/Header.css'
import {Link} from 'react-router-dom'
import Visiting_Card_General_Format from '../Assets/Visiting_Card_General_Format1.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';

function Header() {
    return (
        <div class="header-main-container">
            <Link to="/" class="logo-container">
            <LazyLoadImage
      alt="logo"
      style={{width:"100%",height:"100%",objectFit:"cover"}}
      src={Visiting_Card_General_Format} // use normal <img> attributes as props
       />
            </Link>
            <div class="nav-item-container">
                <Link class="nav-item-container-item" to="/" >HOME</Link>
                <Link class="nav-item-container-item" to="/product" >PRODUCT</Link>
                <Link class="nav-item-container-item" to="/contact" >CONTACT</Link>
                <Link class="nav-item-container-item" to="/solarcalculator" >CALCULATOR</Link>
                
            </div>
        </div>
    )
}

export default Header
