import React,{useState,useEffect} from 'react'
import Fix_navigation_bar from '../ReusableComponent/Fix_navigation_bar.js'
import Header from '../ReusableComponent/Header'
import Footer from '../ReusableComponent/Footer'
import '../CSS/Calculator.css'
import Visiting_Card_General_Format from '../Assets/Visiting_Card_General_Format.png'
import {Link} from 'react-router-dom'
import '../CSS/Home.css'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ContactButton from '../ReusableComponent/ContactButton.js'
import CalculatorButton from '../ReusableComponent/CalculatorButton.js'
import BottomFooter from '../ReusableComponent/BottomFooter.js'

function Calculator() {
    const [menu,setMenu]=useState(false)
    const [electricbill,setelectricbill]=useState(null)
    const [result,setResult]=useState(0)
    const [wattage,setwattage]=useState(0)
    const [quantity,setquantity]=useState(0)
    const [WorkingHours,setWorkingHours]=useState(0)
    const [wattage1,setwattage1]=useState(0)
    const [quantity1,setquantity1]=useState(0)
    const [WorkingHours1,setWorkingHours1]=useState(0)
    const [wattage2,setwattage2]=useState(0)
    const [quantity2,setquantity2]=useState(0)
    const [WorkingHours2,setWorkingHours2]=useState(0)
    const [wattage3,setwattage3]=useState(0)
    const [quantity3,setquantity3]=useState(0)
    const [WorkingHours3,setWorkingHours3]=useState(0)
    const [wattage4,setwattage4]=useState(0)
    const [quantity4,setquantity4]=useState(0)
    const [WorkingHours4,setWorkingHours4]=useState(0)
    const [wattage5,setwattage5]=useState(0)
    const [quantity5,setquantity5]=useState(0)
    const [WorkingHours5,setWorkingHours5]=useState(0)
    const [wattage6,setwattage6]=useState(0)
    const [quantity6,setquantity6]=useState(0)
    const [WorkingHours6,setWorkingHours6]=useState(0)
    const [wattage7,setwattage7]=useState(0)
    const [quantity7,setquantity7]=useState(0)
    const [WorkingHours7,setWorkingHours7]=useState(0)
    const [wattage8,setwattage8]=useState(0)
    const [quantity8,setquantity8]=useState(0)
    const [WorkingHours8,setWorkingHours8]=useState(0)
    const [wattage9,setwattage9]=useState(0)
    const [quantity9,setquantity9]=useState(0)
    const [WorkingHours9,setWorkingHours9]=useState(0)
    const [wattage10,setwattage10]=useState(0)
    const [quantity10,setquantity10]=useState(0)
    const [WorkingHours10,setWorkingHours10]=useState(0)
    const [wattage11,setwattage11]=useState(0)
    const [quantity11,setquantity11]=useState(0)
    const [WorkingHours11,setWorkingHours11]=useState(0)
    const [wattage12,setwattage12]=useState(0)
    const [quantity12,setquantity12]=useState(0)
    const [WorkingHours12,setWorkingHours12]=useState(0)
    const [wattage13,setwattage13]=useState(0)
    const [quantity13,setquantity13]=useState(0)
    const [WorkingHours13,setWorkingHours13]=useState(0)
    const [wattage14,setwattage14]=useState(0)
    const [quantity14,setquantity14]=useState(0)
    const [WorkingHours14,setWorkingHours14]=useState(0)
    const [wattage15,setwattage15]=useState(0)
    const [quantity15,setquantity15]=useState(0)
    const [WorkingHours15,setWorkingHours15]=useState(0)
    const [wattage16,setwattage16]=useState(0)
    const [quantity16,setquantity16]=useState(0)
    const [WorkingHours16,setWorkingHours16]=useState(0)
    const [wattage17,setwattage17]=useState(0)
    const [quantity17,setquantity17]=useState(0)
    const [WorkingHours17,setWorkingHours17]=useState(0)
    const [wattage18,setwattage18]=useState(0)
    const [quantity18,setquantity18]=useState(0)
    const [WorkingHours18,setWorkingHours18]=useState(0)
    const [wattage19,setwattage19]=useState(0)
    const [quantity19,setquantity19]=useState(0)
    const [WorkingHours19,setWorkingHours19]=useState(0)
    const [wattage20,setwattage20]=useState(0)
    const [quantity20,setquantity20]=useState(0)
    const [WorkingHours20,setWorkingHours20]=useState(0)
    const [wattage21,setwattage21]=useState(0)
    const [quantity21,setquantity21]=useState(0)
    const [WorkingHours21,setWorkingHours21]=useState(0)
    const [wattage22,setwattage22]=useState(0)
    const [quantity22,setquantity22]=useState(0)
    const [WorkingHours22,setWorkingHours22]=useState(0)
    const [wattage23,setwattage23]=useState(0)
    const [quantity23,setquantity23]=useState(0)
    const [WorkingHours23,setWorkingHours23]=useState(0)
    const [wattage24,setwattage24]=useState(0)
    const [quantity24,setquantity24]=useState(0)
    const [WorkingHours24,setWorkingHours24]=useState(0)
    const [wattage25,setwattage25]=useState(0)
    const [quantity25,setquantity25]=useState(0)
    const [WorkingHours25,setWorkingHours25]=useState(0)
    const [wattage26,setwattage26]=useState(0)
    const [quantity26,setquantity26]=useState(0)
    const [WorkingHours26,setWorkingHours26]=useState(0)
    const [wattage27,setwattage27]=useState(0)
    const [quantity27,setquantity27]=useState(0)
    const [WorkingHours27,setWorkingHours27]=useState(0)
    const [wattage28,setwattage28]=useState(0)
    const [quantity28,setquantity28]=useState(0)
    const [WorkingHours28,setWorkingHours28]=useState(0)
    const [wattage29,setwattage29]=useState(0)
    const [quantity29,setquantity29]=useState(0)
    const [WorkingHours29,setWorkingHours29]=useState(0)
    const [wattage30,setwattage30]=useState(0)
    const [quantity30,setquantity30]=useState(0)
    const [WorkingHours30,setWorkingHours30]=useState(0)
    const [wattage31,setwattage31]=useState(0)
    const [quantity31,setquantity31]=useState(0)
    const [WorkingHours31,setWorkingHours31]=useState(0)
    const [wattage32,setwattage32]=useState(0)
    const [quantity32,setquantity32]=useState(0)
    const [WorkingHours32,setWorkingHours32]=useState(0)
  
    const [TotalConsumption,setTotalConsumption]=useState(0)
    const [ConsumptionwithBuffer20,setConsumptionwithBuffer20]=useState(0)
    const [ProductionRequired,setProductionRequired]=useState(0)
    const [PanelRequired,setPanelRequired]=useState(0)
    const [BatteriesRequired,setBatteriesRequired]=useState(0)
    const [InverterRequired,setInverterRequired]=useState(0)
    const [isElectricity,setisElectricity]=useState(false)
   const [isAppliance,setAppliance]=useState(false)
   const [isTableFan,setTableFan]=useState(false)
   const [isCealingFan,setCealingFan]=useState(false)
   const [isRoomCooler,setRoomCooler]=useState(false)
   const [isLaptop,setLaptop]=useState(false)
   const [isDesktopComputer,setDesktopComputer]=useState(false)
   const [isPrinter,setPrinter]=useState(false)
   const [isTubelight,setTubelight]=useState(false)
   const [isCFLHeavy,setCFLHeavy]=useState(false)
   const [isCFLLight,setCFLLight]=useState(false)
   const [isLEDBuld,setLEDBuld]=useState(false)
   const [isLightBuld,setLightBuld]=useState(false)
   const [isJuiceMixerGrinder,setJuiceMixerGrinder]=useState(false)
   const [isToaster,setToaster]=useState(false)
   const [isRefrigetorUpto200,setRefrigetorUpto200]=useState(false)
   const [isRefrigetorUpto500,setRefrigetorUpto500]=useState(false)
   const [isMicroOven,setMicroOven]=useState(false)
   const [isVaccumCleaner,setVaccumCleaner]=useState(false)
   const [isWashingMachine,setWashingMachine]=useState(false)
   const [isGeyserWaterHeater,setGeyserWaterHeater]=useState(false)
   const [isRoomHeater,setRoomHeater]=useState(false)
   const [isTVLEDUpto40,setTVLEDUpto40]=useState(false)
   const [isTVCRTUpto21,setTVCRTUpto21]=useState(false)
   const [isTVPlasma,setTVPlasma]=useState(false)
   const [isSetTopBox,setSetTopBox]=useState(false)
   const [isMusicSystem,setMusicSystem]=useState(false)
   const [isGamingConsole,setGamingConsole]=useState(false)
   const [isAirConditioner,setAirConditioner]=useState(false)
   const [isWaterPump,setWaterPump]=useState(false)
   const [isPhotoCopier,setPhotoCopier]=useState(false)
   const [isOfficePrinter,setOfficePrinter]=useState(false)
   const [isPetrolFilling,setPetrolFilling]=useState(false)
   const [isProjector,setProjector]=useState(false)
   const [isSurvellianceSystem,setSurvellianceSystem]=useState(false)
   const [isArea,setArea]=useState(null)
   const showMenuBar=()=>{
        setMenu(!menu)
    }

    
    const showTableFan=()=>{
        setTableFan(!isTableFan)
    }

    const showCeilingFan=()=>{
        setCealingFan(!isCealingFan)
    }

    const showRoomCooler=()=>{
        setRoomCooler(!isRoomCooler)
    }

    const showLaptop=()=>{
        setLaptop(!isLaptop)
    }

    const showDesktopComputer=()=>{
        setDesktopComputer(!isDesktopComputer)
    }

    const showPrinter=()=>{
        setPrinter(!isPrinter)
    }

    const showTubeLight=()=>{
        setTubelight(!isTubelight)
    }

    const showCFLHeavy=()=>{
        setCFLHeavy(!isCFLHeavy)
    }

    const showCFLLight=()=>{
        setCFLLight(!isCFLLight)
    }

    const showLEDBuld=()=>{
        setLEDBuld(!isLEDBuld)
    }

    const showLightBuld=()=>{
        setLightBuld(!isLightBuld)
    }

    const showJuicer=()=>{
        setJuiceMixerGrinder(!isJuiceMixerGrinder)
    }

    const showToaster=()=>{
        setToaster(!isToaster)
    }

    const showRefrigeratorUpto200=()=>{
        setRefrigetorUpto200(!isRefrigetorUpto200)
    }

    const showRefrigeratorUpto500=()=>{
        
        setRefrigetorUpto500(!isRefrigetorUpto500)
    }

    const showMicroOven=()=>{
        setMicroOven(!isMicroOven)
    }

    const showVaccumCleaner=()=>{
        setVaccumCleaner(!isVaccumCleaner)
    }

    const showWashingMachine=()=>{
        setWashingMachine(!isWashingMachine)
    }

    const showGeyser=()=>{
        setGeyserWaterHeater(!isGeyserWaterHeater)
    }

    const showRoomHeater=()=>{
        setRoomHeater(!isRoomHeater)
    }

    const showTVLEDUpto40=()=>{
        setTVLEDUpto40(!isTVLEDUpto40)
    }

    const showTVCRTUpto20=()=>{
        setTVCRTUpto21(!isTVCRTUpto21)
    }

    const showTVPlasma=()=>{
        setTVPlasma(!isTVPlasma)
    }

    const showSetup=()=>{
        setSetTopBox(!isSetTopBox)
    }

    const showMusicSystem=()=>{
        setMusicSystem(!isMusicSystem)
    }

    const showGamingConsole=()=>{
        setGamingConsole(!isGamingConsole)
    }

    const showAirConditioner=()=>{
        setAirConditioner(!isAirConditioner)
    }

    const showWaterPump=()=>{
        setWaterPump(!isWaterPump)
    }

    const showPhotoCopier=()=>{
        setPhotoCopier(!isPhotoCopier)
    }

    const showOffice=()=>{
        setOfficePrinter(!isOfficePrinter)
    }

    const showPetrolFillingMachine=()=>{
        setPetrolFilling(!isPetrolFilling)
    }

    const showProjector=()=>{
        setProjector(!isProjector)
    }

    const showSurvelliance=()=>{
        setSurvellianceSystem(!isSurvellianceSystem)
    }
    

    const calculate=()=>{
        const result=((electricbill/7)/135)
        setResult(result)

    }

    const calculate2=()=>{
        const total=(wattage*quantity*WorkingHours)+(wattage1*quantity1*WorkingHours1)+(wattage2*quantity2*WorkingHours2)+(wattage3*quantity3*WorkingHours3)+(wattage4*quantity4*WorkingHours4)+(wattage5*quantity5*WorkingHours5)+(wattage6*quantity6*WorkingHours6)+(wattage7*quantity7*WorkingHours7)+(wattage8*quantity8*WorkingHours8)+(wattage9*quantity9*WorkingHours9)+(wattage10*quantity10*WorkingHours10)+(wattage11*quantity11*WorkingHours11)+(wattage12*quantity12*WorkingHours12)+(wattage13*quantity13*WorkingHours13)+(wattage14*quantity14*WorkingHours14)+(wattage15*quantity15*WorkingHours15)+(wattage16*quantity16*WorkingHours16)+(wattage17*quantity17*WorkingHours17)+(wattage18*quantity18*WorkingHours18)+(wattage19*quantity19*WorkingHours19)+(wattage20*quantity20*WorkingHours20)+(wattage21*quantity21*WorkingHours21)+(wattage22*quantity22*WorkingHours22)+(wattage23*quantity23*WorkingHours23)+(wattage24*quantity24*WorkingHours24)+(wattage25*quantity25*WorkingHours25)+(wattage26*quantity26*WorkingHours26)+(wattage27*quantity27*WorkingHours27)+(wattage28*quantity28*WorkingHours28)+(wattage29*quantity29*WorkingHours29)+(wattage30*quantity30*WorkingHours30)+(wattage31*quantity31*WorkingHours31)+(wattage32*quantity32*WorkingHours32)
        const totalWattage=(wattage*quantity)+(wattage1*quantity1)+(wattage2*quantity2)+(wattage3*quantity3)+(wattage4*quantity4)+(wattage5*quantity5)+(wattage6*quantity6)+(wattage7*quantity7)+(wattage8*quantity8)+(wattage9*quantity9)+(wattage10*quantity10)+(wattage11*quantity11)+(wattage12*quantity12)+(wattage13*quantity13)+(wattage14*quantity14)+(wattage15*quantity15)+(wattage16*quantity16)+(wattage17*quantity17)+(wattage18*quantity18)+(wattage19*quantity19)+(wattage20*quantity20)+(wattage21*quantity21)+(wattage22*quantity22)+(wattage23*quantity23)+(wattage24*quantity24)+(wattage25*quantity25)+(wattage26*quantity26)+(wattage27*quantity27)+(wattage28*quantity28)+(wattage29*quantity29)+(wattage30*quantity30)+(wattage31*quantity31)+(wattage32*quantity32)
        setTotalConsumption(total)
        setConsumptionwithBuffer20(total*1.2)
        setProductionRequired(total*1.2)
        setPanelRequired(total/4500)
        setBatteriesRequired((total/2)/1500)
        setInverterRequired(totalWattage)
    }

    const ElectricBtnClick=()=>{
        if(isArea!==null){
            if(isAppliance){
                setAppliance(false)
            }
        setisElectricity(!isElectricity)
        }else{
            alert("please select area type...")
        }
    }
   
    const ApplianceBtnClick=()=>{
        if(isArea!==null){
            if(isElectricity){
                setisElectricity(false)
            }
        setAppliance(!isAppliance)
        }else{
            alert("please select area type...")
         
        }
    }
   
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      
   
    return (
        <div style={{position:"relative"}} >
            <ContactButton/>
            <CalculatorButton/>
                  {menu===false?
            <div className="menu-container" >
           <i  onClick={showMenuBar} class="material-icons menu1" >menu</i>
           <LazyLoadImage
      alt="sliderimage"
      style={{width:100,height:100}}
      src={Visiting_Card_General_Format} // use normal <img> attributes as props
       />
       <div className="menu-header" >ARM Solar Solution</div>
           </div>
           :
           <div className="siderbar" >
                <div className="siderbar-logo-container">
                <LazyLoadImage
      alt="sliderimage"
      style={{width:100,height:100}}
      src={Visiting_Card_General_Format} // use normal <img> attributes as props
       />
       <div className="menu-header1" >ARM Solar Solution</div>
       
       <div onClick={showMenuBar} className="siderbar-cancel-button" >
        X
       </div>
                </div>
                <div className="siderbar-item" >
                    <Link className="siderbar-item1" to="/" >
                    Home
                    </Link>
           
       </div>
       <div className="siderbar-item" >
           <Link className="siderbar-item1" to="/product" >
           Product
           </Link>
       </div>
        <div className="siderbar-item" >
           <Link className="siderbar-item1" to="/contact" >Contact</Link>
       </div>
        <div className="siderbar-item" >
           <Link className="siderbar-item1" to="/solarcalculator" >Calculator</Link>
       </div>

           </div>
            }
       

            <Fix_navigation_bar />
            <div class="header-main-container">
            <Link to="/" class="logo-container">
            <LazyLoadImage
      alt="logo"
      style={{width:"100%",height:"100%"}}
      src={Visiting_Card_General_Format} // use normal <img> attributes as props
       />
            </Link>
            <div class="nav-item-container">
                <Link class="nav-item-container-item " to="/" >HOME</Link>
                <Link class="nav-item-container-item " to="/product" >PRODUCT</Link>
                <Link class="nav-item-container-item active" to="/solarcalculator" >CALCULATOR</Link>
               
                <Link class="nav-item-container-item " to="/contact" >CONTACT</Link>
                
            </div>
        </div>
   
            <div class="calculator-container">
                <div className="calculator-form" >
                    <div className="calculator-form-header" >
                    <strong>Solar calculator</strong>
                
                    </div>
                  <h1  >Select an option to get the “Best-Fit” Solar Recommendation</h1>
                  <div  className="calculator-form-pincode-container" >
                  <div  className="option-box-container"  >
        <input  onChange={(event)=> setArea(event.target.value)}  type="radio" value="Residential" className="option-box-header" name="Redinesy"/> Residential
        <input onChange={(event)=>setArea(event.target.value)}  type="radio" value="Commercial" className="option-box-header" name="Redinesy"/> Commercial
        <input onChange={(event)=>setArea(event.target.value)} type="radio" value="Industrial" className="option-box-header" name="Redinesy"/> Industrial
     
      </div>
                   </div>

                <div className="button-container" >
                        <div onClick={()=>ElectricBtnClick()} className="electricity-bill-button" >
                        I KNOW MY <span style={{color:"yellow",marginLeft:5}} >   ELECTIRCITY BILL</span>

                        </div>
                        <h2>OR</h2>
                        <div onClick={()=>ApplianceBtnClick()} className="electricity-bill-button" >
                        I KNOW MY <span style={{color:"yellow",marginLeft:5}} >  APPLIANCES </span>

                    </div>

                    </div>

                    <div className="another-container" >
                    <div className="header-for-another-container" >
                    Existing Home Owners
                    </div>
                    <div className="header-for-another-container" >
                    Moving into a New Home
                    </div>
                </div>
                {isElectricity?
                <div className="electricity-bill-container" >
                    <div>
                    <input onChange={(e)=>setelectricbill(e.target.value)}  placeholder="Enter electricity bill"  className="electricity-bill-input-container" type="number" id="fname" name="fname" ></input>
              
                    </div>
                    <div onClick={()=>calculate()} className="calculate-btn" >
                    CALCULATE MY SAVING
                    </div>

                </div>:<></>
                }
                {isAppliance?
                <div>
                    <div className="two-appliances-container" >

                <div className="appliances-container" >
                    <div className="contact-container2-header" >
                       <div> Table Fan</div>
                       <i onClick={()=> showTableFan()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                    {isTableFan?
                    <div className="table-fan-input-container" >
                        
                    <div  >
                        <input onChange={(e)=>setwattage(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div  >
                        <input onChange={(e)=>setquantity(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div  >
                        <input onChange={(e)=>setWorkingHours(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>
                    }
                    
                </div>
                <div className="appliances-container" >
                <div className="contact-container2-header" >
                       <div>Ceiling Fan</div>
                       <i onClick={()=> showCeilingFan()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                   {isCealingFan?
                    <div className="table-fan-input-container" >
                    <div style={{width:"50%"}} >
                        <input onChange={(e)=>setwattage1(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setquantity1(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setWorkingHours1(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>
                   }
                    
                </div>
                </div>
                
                <div className="two-appliances-container" >
                <div className="appliances-container" >
                <div className="contact-container2-header" >
                       <div>Room Cooler</div>
                       <i onClick={()=>showRoomCooler()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                   {isRoomCooler?
                     <div className="table-fan-input-container" >
                    <div style={{width:"50%"}} >
                        <input onChange={(e)=>setwattage2(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setquantity2(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setWorkingHours2(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>
                    }
                    
                </div>

                <div className="appliances-container" >
                <div className="contact-container2-header" >
                       <div>Laptop</div>
                       <i onClick={()=>showLaptop()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                    {isLaptop?
                    <div className="table-fan-input-container" >
                    <div style={{width:"50%"}} >
                        <input onChange={(e)=>setwattage3(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setquantity3(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setWorkingHours3(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>
                    }
                    
                </div>
                </div>
                
                <div className="two-appliances-container" >
                <div className="appliances-container" >
                <div className="contact-container2-header" >
                       <div>Desktop Computer</div>
                       <i onClick={()=>showDesktopComputer()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                    {isDesktopComputer?
                    <div className="table-fan-input-container" >
                    <div style={{width:"50%"}} >
                        <input onChange={(e)=>setwattage4(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setquantity4(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setWorkingHours4(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>}
                    
                </div>

                <div className="appliances-container" >
                <div className="contact-container2-header" >
                       <div>Printer (Small)</div>
                       <i onClick={()=>showPrinter()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                    {isPrinter?
                    <div className="table-fan-input-container" >
                    <div style={{width:"50%"}} >
                        <input onChange={(e)=>setwattage5(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setquantity5(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setWorkingHours5(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>
                    }
                    
                </div>
                </div>

                <div className="two-appliances-container" >
                <div className="appliances-container" >
                <div className="contact-container2-header" >
                       <div>Tubelight</div>
                       <i onClick={()=>showTubeLight()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                    {isTubelight?
                    <div className="table-fan-input-container" >
                    <div style={{width:"50%"}} >
                        <input onChange={(e)=>setwattage6(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setquantity6(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setWorkingHours6(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>
                    }
                    
                </div>


                <div className="appliances-container" >
                <div className="contact-container2-header" >
                       <div>CFL Heavy</div>
                       <i onClick={()=>showCFLHeavy()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                    {isCFLHeavy?
                    <div className="table-fan-input-container" >
                    <div style={{width:"50%"}} >
                        <input onChange={(e)=>setwattage7(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setquantity7(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setWorkingHours7(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>
                    }
                    
                </div>
                </div>

                <div className="two-appliances-container" >
                <div className="appliances-container" >
                <div className="contact-container2-header" >
                       <div>CFL Light</div>
                       <i onClick={()=>showCFLLight()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                    {isCFLLight?
                    <div className="table-fan-input-container" >
                    <div style={{width:"50%"}} >
                        <input onChange={(e)=>setwattage8(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setquantity8(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setWorkingHours8(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>
                    }   
                    
                </div>


                <div className="appliances-container" >
                <div className="contact-container2-header" >
                       <div>LED Bulb</div>
                       <i onClick={()=>showLEDBuld()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                    {isLEDBuld?
                    <div className="table-fan-input-container" >
                    <div style={{width:"50%"}} >
                        <input onChange={(e)=>setwattage9(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setquantity9(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setWorkingHours9(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>
                    }
                    
                </div>
                </div>

                <div className="two-appliances-container" >
                <div className="appliances-container" >
                <div className="contact-container2-header" >
                       <div>Light Bulb (Incandescent)</div>
                       <i onClick={()=>showLightBuld()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                    {isLightBuld?
                    <div className="table-fan-input-container" >
                    <div style={{width:"50%"}} >
                        <input onChange={(e)=>setwattage10(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setquantity10(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setWorkingHours10(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>
                    }
                    
                </div>


                <div className="appliances-container" >
                <div className="contact-container2-header" >
                       <div>Juicer Mixer Grinder</div>
                       <i onClick={()=>showJuicer()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                    {isJuiceMixerGrinder?
                    <div className="table-fan-input-container" >
                    <div style={{width:"50%"}} >
                        <input onChange={(e)=>setwattage11(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setquantity11(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setWorkingHours11(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>
                    }
                    
                </div>
                </div>

                <div className="two-appliances-container" >
                <div className="appliances-container" >
                <div className="contact-container2-header" >
                       <div>Toaster</div>
                       <i onClick={()=>showToaster()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                    {isToaster?
                    <div className="table-fan-input-container" >
                    <div style={{width:"50%"}} >
                        <input onChange={(e)=>setwattage12(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setquantity12(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setWorkingHours12(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>
                    }
                    
                </div>


                <div className="appliances-container" >
                <div className="contact-container2-header" >
                       <div>Refrigerator (upto 200L)</div>
                       <i onClick={()=>showRefrigeratorUpto200()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                    {isRefrigetorUpto200?
                    <div className="table-fan-input-container" >
                    <div style={{width:"50%"}} >
                        <input onChange={(e)=>setwattage13(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setquantity13(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setWorkingHours13(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>
                    }
                    
                </div>
                </div>

                <div className="two-appliances-container" >
                <div className="appliances-container" >
                <div className="contact-container2-header" >
                       <div>Refrigerator (upto 500L)</div>
                       <i onClick={()=>showRefrigeratorUpto500()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                    {isRefrigetorUpto500?
                    <div className="table-fan-input-container" >
                    <div style={{width:"50%"}} >
                        <input onChange={(e)=>setwattage14(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setquantity14(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setWorkingHours14(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>
                    }
                    
                </div>


                <div className="appliances-container" >
                <div className="contact-container2-header" >
                       <div>Microwave Oven</div>
                       <i onClick={()=>showMicroOven()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                    {isMicroOven?
                    <div className="table-fan-input-container" >
                    <div style={{width:"50%"}} >
                        <input onChange={(e)=>setwattage15(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setquantity15(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setWorkingHours15(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>
                    }
                    
                </div>
                </div>

                <div className="two-appliances-container" >
                <div className="appliances-container" >
                <div className="contact-container2-header" >
                       <div>Vaccum Cleaner</div>
                       <i onClick={()=>showVaccumCleaner()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                    {isVaccumCleaner?
                    <div className="table-fan-input-container" >
                    <div style={{width:"50%"}} >
                        <input onChange={(e)=>setwattage16(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setquantity16(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setWorkingHours16(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>
                    }
                    
                </div>


                <div className="appliances-container" >
                <div className="contact-container2-header" >
                       <div>Washing Machine</div>
                       <i onClick={()=>showWashingMachine()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                    {isWashingMachine?
                    <div className="table-fan-input-container" >
                    <div style={{width:"50%"}} >
                        <input onChange={(e)=>setwattage17(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setquantity17(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setWorkingHours17(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>
                    }
                    
                </div>
                </div>

                <div className="two-appliances-container" >
                <div className="appliances-container" >
                <div className="contact-container2-header" >
                       <div>Geyser/Water Heater</div>
                       <i onClick={()=>showGeyser()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                    {isGeyserWaterHeater?
                    <div className="table-fan-input-container" >
                    <div style={{width:"50%"}} >
                        <input onChange={(e)=>setwattage18(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setquantity18(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>  
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setWorkingHours18(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>
                    }
                    
                </div>


                <div className="appliances-container" >
                <div className="contact-container2-header" >
                       <div>Room Heater</div>
                       <i onClick={()=>showRoomHeater()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                    {isRoomHeater?
                    <div className="table-fan-input-container" >
                    <div style={{width:"50%"}} >
                        <input onChange={(e)=>setwattage19(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setquantity19(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setWorkingHours19(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>
                    }
                    
                </div>
                </div>
                
                <div className="two-appliances-container" >
                <div className="appliances-container" >
                <div className="contact-container2-header" >
                       <div>TV LED (upto 40")</div>
                       <i onClick={()=>showTVLEDUpto40()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                    {isTVLEDUpto40?
                    <div className="table-fan-input-container" >
                    <div style={{width:"50%"}} >
                        <input onChange={(e)=>setwattage20(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setquantity20(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setWorkingHours20(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>
                    }
                    
                </div>


                <div className="appliances-container" >
                <div className="contact-container2-header" >
                       <div>TV CRT (upto 21")</div>
                       <i onClick={()=>showTVCRTUpto20()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                    {isTVCRTUpto21?
                    <div className="table-fan-input-container" >
                    <div style={{width:"50%"}} >
                        <input onChange={(e)=>setwattage21(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setquantity21(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setWorkingHours21(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>
                    }
                    
                </div>
                </div>

                <div className="two-appliances-container" >
                <div className="appliances-container" >
                <div className="contact-container2-header" >
                       <div>TV Plasma</div>
                       <i onClick={()=>showTVPlasma()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                    {isTVPlasma?
                    <div className="table-fan-input-container" >
                    <div style={{width:"50%"}} >
                        <input onChange={(e)=>setwattage22(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setquantity22(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setWorkingHours22(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>
                    }
                    
                </div>


                <div className="appliances-container" >
                <div className="contact-container2-header" >
                       <div>Set Top Box (DTH)</div>
                       <i onClick={()=>showSetup()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                    {isSetTopBox?
                    <div className="table-fan-input-container" >
                    <div style={{width:"50%"}} >
                        <input onChange={(e)=>setwattage23(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setquantity23(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setWorkingHours23(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>
                    }
                    
                </div>
                </div>

                <div className="two-appliances-container" >
                <div className="appliances-container" >
                <div className="contact-container2-header" >
                       <div>Music System</div>
                       <i onClick={()=>showMusicSystem()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                    {isMusicSystem?
                    <div className="table-fan-input-container" >
                    <div style={{width:"50%"}} >
                        <input onChange={(e)=>setwattage24(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setquantity24(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setWorkingHours24(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>
                    }
                    
                </div>


                <div className="appliances-container" >
                <div className="contact-container2-header" >
                       <div>Gaming Console</div>
                       <i onClick={()=>showGamingConsole()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                    {isGamingConsole?
                    <div className="table-fan-input-container" >
                    <div style={{width:"50%"}} >
                        <input onChange={(e)=>setwattage25(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setquantity25(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setWorkingHours25(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>
                    }
                    
                </div>
                </div>

                <div className="two-appliances-container" >
                <div className="appliances-container" >
                <div className="contact-container2-header" >
                       <div>Air Conditioner (1 Ton, 3 star)</div>
                       <i onClick={()=>showAirConditioner()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                    {isAirConditioner?
                    <div className="table-fan-input-container" >
                    <div style={{width:"50%"}} >
                        <input onChange={(e)=>setwattage26(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setquantity26(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setWorkingHours26(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>
                    }
                    
                </div>



                <div className="appliances-container" >
                <div className="contact-container2-header" >
                       <div>Water Pump (0.5 HP)</div>
                       <i onClick={()=>showWaterPump()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                    {isWaterPump?
                    <div className="table-fan-input-container" >
                    <div style={{width:"50%"}} >
                        <input onChange={(e)=>setwattage27(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setquantity27(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setWorkingHours27(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>
                    }
                    
                </div>
                </div>

                <div className="two-appliances-container" >
                <div className="appliances-container" >
                <div className="contact-container2-header" >
                       <div>Photo Copier</div>
                       <i onClick={()=>showPhotoCopier()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                    {isPhotoCopier?
                    <div className="table-fan-input-container" >
                    <div style={{width:"50%"}} >
                        <input onChange={(e)=>setwattage28(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setquantity28(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setWorkingHours28(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>
                    }
                    
                </div>


                <div className="appliances-container" >
                <div className="contact-container2-header" >
                       <div>Office Printer/Scanner</div>
                       <i onClick={()=>showOffice()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                    {isOfficePrinter?
                    <div className="table-fan-input-container" >
                    <div style={{width:"50%"}} >
                        <input onChange={(e)=>setwattage29(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setquantity29(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setWorkingHours29(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>
                    }
                    
                </div>
                </div>

                <div className="two-appliances-container" >
                <div className="appliances-container" >
                <div className="contact-container2-header" >
                       <div>Petrol Filling Machine</div>
                       <i onClick={()=>showPetrolFillingMachine()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                    {isPetrolFilling?
                    <div className="table-fan-input-container" >
                    <div style={{width:"50%"}} >
                        <input onChange={(e)=>setwattage30(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setquantity30(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setWorkingHours30(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>
                    }       
                    
                </div>


                <div className="appliances-container" >
                <div className="contact-container2-header" >
                       <div>Projector</div>
                       <i onClick={()=>showProjector()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                    {isProjector?
                    <div className="table-fan-input-container" >
                    <div style={{width:"50%"}} >
                        <input onChange={(e)=>setwattage31(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setquantity31(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setWorkingHours31(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>
                    }
                    
                </div>
                </div>
                <div className="two-appliances-container" >
                <div className="appliances-container" >
                <div className="contact-container2-header" >
                       <div>Survelliance System</div>
                       <i onClick={()=>showSurvelliance()} class="material-icons dropdown-arrow ">arrow_drop_down</i>
                    </div>
                    {isSurvellianceSystem?
                    <div className="table-fan-input-container" >
                    <div style={{width:"50%"}} >
                        <input onChange={(e)=>setwattage32(e.target.value)} placeholder="Wattage"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setquantity32(e.target.value)} placeholder="Quantity"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                        <div style={{width:"50%"}} >
                        <input onChange={(e)=>setWorkingHours32(e.target.value)} placeholder="Working Hours"  className="input-text-for-name1" type="number" id="fname" name="fname" ></input>
                      
                        </div>
                   
                   
                    </div>:<></>
                    }
                   
                   
                    
                </div>
                </div>
                <div className="calculator-page-button-container" >
                <div onClick={()=>calculate2()} className="calculate-btn2" >
                    CALCULATE MY SAVING
                    </div>
                    </div>

               
                </div>:<></>
                }





              
                </div>

                
              
               
            </div>
            {
                result!=0||TotalConsumption!=0?
                <>
{isElectricity||isAppliance?
            <div className="images-container" >
                <div className="output-container" >
                    <strong>Output</strong>
                    {
                        isElectricity?
                        <div className="output-of-electricity-bill" >
                            Solar Required:<b> {result.toFixed(2)} kW</b>
                        </div>:
                        <></>
                    }
                    {
                        isAppliance?
                        <div className="output-of-electricity-bill" >
                    Total Consumption:<b> {TotalConsumption.toFixed(2)}</b> <br/>
                    Consumption with Buffer 20%:<b> {ConsumptionwithBuffer20.toFixed(2)}</b> <br/>
                    Production Required:<b> {ProductionRequired.toFixed(2)}</b> <br/>
                    Panel Required:<b> {PanelRequired.toFixed(2)}</b> <br/>
                    Batteries Required:<b> {BatteriesRequired.toFixed(2)}</b> <br/>
                    Inverter Required:<b> {InverterRequired.toFixed(2)}</b> <br/>
                  
                        </div>:<></>
                    }
                      <div className="output-container-bottom-container" >
                    <Link to="/contact" className="calculate-btn2" >Enquiry</Link>
                    <div className="calculate-btn2" >Call now</div>

                </div>
            
                </div>
              </div>:<></>
                
                }
            
                </>
                
                :<></>
            }
            
              <Footer/>
              <BottomFooter/>
        </div>
    )
}

export default Calculator
