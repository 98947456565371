import React,{useState,useRef,useEffect} from 'react'
import '../CSS/Footer.css'
import '../CSS/Home.css'
const studentStories=[
    {
        img:require('../Assets/luminous-logo.jpg'),
        paragraph:'Proud to pursuing B.Arch in this institute. The nature around the college is really very mesmerizing',
        name:'Rama Pawaskar'
    },
    {
        img:require('../Assets/luminous-logo.jpg'),
        paragraph:'One of the best architectural institute, a peaceful environment Pleasing  environment',
        name:'Sumedh Gadankush'
    },
    {
        img:require('../Assets/luminous-logo.jpg'),
        paragraph:'good faculty and also good coordination between students and teachers. Pleasing environment',
        name:'Saloni Shirke'
    },
    {
        img:require('../Assets/luminous-logo.jpg'),
        paragraph:'Good coordination between faculty member and students. Pleasing  environment',
        name:'Shivam Patil'
    }

]
const news=[
    {
        header:"UK PM praises India's 'incredible' solar power strides at climate summit",
    },
    {
        header:"Waaree Energies launches financing facility for solar power projects"
    },
    {
        header:"Mizoram enters solar map of country with 2-MW photovoltaic"
    },
    
]
var i=0;

function useInterval(callback, delay) {
    const savedCallback = useRef();
  
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      let id = setInterval(() => {
        savedCallback.current();
      }, delay);
      return () => clearInterval(id);
    }, [delay]);
  }

function Footer() {
    const [key,setKey]=useState(0)
    const [index,setIndex]=useState(0)
    useInterval(()=>{
        if(key<3){
            setKey(key+1)
        }else{
            setKey(0)
        }
       
      },1500)

      useInterval(()=>{
        if(index<2){
            setIndex(index+1)
        }else{
            setIndex(0)
        }
       
      },1500)


    
    return (
        <div class="home-footer-container" >
            
            <div class="home-footer-container1" >
            
               
                <div class="testimoniyal-container">
                <div class="testimoniyal-content" >
                <div className="testimoniyal-content-header" >Testimonial</div>
            
                <p>
                 "{studentStories[key].paragraph}"
                    </p>
                    <div className="testimoniyal-content-name" >
                        {studentStories[key].name}
                    </div>
            
                </div>
              
                </div>
             </div>
             <div class="home-footer-container2" >
                 <div className="home-footer-container2-header" >News</div>
                 <div className="rolling-solar-news" >

                 <a target="_blank" className="rolling-solar-news-subcontainer "  href="https://economictimes.indiatimes.com/industry/energy/power/uk-pm-praises-indias-incredible-solar-power-strides-at-climate-summit/articleshow/79707204.cms" >{news[index].header} </a>
                 <a className="testimoniyal-content-name" style={{display:"flex",alignItems:"center",justifyContent:"center"}} >
                    The Economics Times
                    </a>
                
                    </div>
                   
              </div>

         </div>
     
        )
}

export default Footer
