import React, { useState,useEffect } from "react";
import Fix_navigation_bar from "../ReusableComponent/Fix_navigation_bar";
import Header from "../ReusableComponent/Header";
import Footer from "../ReusableComponent/Footer";
import "../CSS/Product.css";
import "../CSS/Home.css";

import Select from "react-select";
import image from "../Assets/luminous-logo.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Visiting_Card_General_Format from "../Assets/Visiting_Card_General_Format.png";
import { Link } from "react-router-dom";
import ContactButton from "../ReusableComponent/ContactButton";
import CalculatorButton from "../ReusableComponent/CalculatorButton";
import BottomFooter from "../ReusableComponent/BottomFooter";
import image2 from "../Assets/Okaya-Logo.png";
import image3 from "../Assets/Waaree-logo.png";
import image4 from '../Assets/waacab-logo-website.png'
const options = [
  { value: "Solar Modules", label: "Solar Modules" },
  { value: "Solar Grid-tie Inverter", label: "Solar Grid-tie Inverter" },
  { value: "Solar Off-grid Inverters (PCU’s)", label: "Solar Off-grid Inverters (PCU’s)" },
  { value: "Solar UPS", label: "Solar UPS" },
  { value: "Solar Battery", label: "Solar Battery" },
  { value: "Solar On-Grid System", label: "Solar On-Grid System" },
  { value: "Solar Off-grid System", label: "Solar Off-grid System" },
  { value: "Solar Water Heater", label: "Solar Water Heater" },
  { value: "Solar Street Light", label: "Solar Street Light" },
  { value: "Solar Water Pump", label: "Solar Water Pump" },
  { value: "Solar Structure", label: "Solar Structure" },
  { value: "Solar Distribution Box", label: "Solar Distribution Box" },
  { value: "Household Wires", label: "Household Wires" },
  
];

function Product() {
  const [menu, setMenu] = useState(false);
  const [product,setProduct]=useState(null)

  const handleChange = selectedOption => {
    setProduct( selectedOption );
    if(selectedOption.value==="Solar Modules"){
     
      window.document.getElementById("Solar-Modules").focus()
    }
    if(selectedOption.value==="Solar Grid-tie Inverter"){
      window.document.getElementById("Solar-Grid-tie-Inverter").focus()
      
    }
    if(selectedOption.value==="Solar Off-grid Inverters (PCU’s)"){
      window.document.getElementById("Solar-Off-grid-Inverters-(PCU’s)").focus()
      
    }
    if(selectedOption.value==="Solar UPS"){
      window.document.getElementById("Solar-UPS").focus()
      
    }
    if(selectedOption.value==="Solar Battery"){
      window.document.getElementById("Solar-Battery").focus()
      
    }
    if(selectedOption.value==="Solar On-Grid System"){
      window.document.getElementById("Solar-On-Grid-System").focus()
   
    }
    if(selectedOption.value==="Solar Off-grid System"){
      window.document.getElementById("Solar-Off-grid-System").focus()
   
    }
    if(selectedOption.value==="Solar Water Heater"){
      window.document.getElementById("Solar-Water-Heater").focus()
   
    }
    if(selectedOption.value==="Solar Street Light"){
      window.document.getElementById("Solar-Street-Light").focus()
   
    }
    if(selectedOption.value==="Solar Water Pump"){
      window.document.getElementById("Solar-Water-Pump").focus()
   
    }
    if(selectedOption.value==="Solar Structure"){
      window.document.getElementById("Solar-Structure").focus()
   
    }
    if(selectedOption.value==="Solar Distribution Box"){
      window.document.getElementById("Solar-Distribution-Box").focus()
   
    }
    if(selectedOption.value==="Household Wires"){
      window.document.getElementById("Household-Wires").focus()
   
    }

};

  const showMenuBar = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div class="product-main-container">
      <ContactButton />
      <CalculatorButton />
      {menu === false ? (
        <div className="menu-container">
          <i onClick={showMenuBar} class="material-icons menu1">
            menu
          </i>
          <LazyLoadImage
            alt="sliderimage"
            style={{ width: 100, height: 100 }}
            src={Visiting_Card_General_Format} // use normal <img> attributes as props
          />
          <div className="menu-header">ARM Solar Solution</div>
        </div>
      ) : (
        <div className="siderbar">
          <div className="siderbar-logo-container">
            <LazyLoadImage
              alt="sliderimage"
              style={{ width: 100, height: 100 }}
              src={Visiting_Card_General_Format} // use normal <img> attributes as props
            />
            <div className="menu-header1">ARM Solar Solution</div>

            <div onClick={showMenuBar} className="siderbar-cancel-button">
              X
            </div>
          </div>
          <div className="siderbar-item">
            <Link className="siderbar-item1" to="/">
              Home
            </Link>
          </div>
          <div className="siderbar-item">
            <Link className="siderbar-item1" to="/product">
              Product
            </Link>
          </div>
          <div className="siderbar-item">
            <Link className="siderbar-item1" to="/contact">
              Contact
            </Link>
          </div>
          <div className="siderbar-item">
            <Link className="siderbar-item1" to="/solarcalculator">
              Calculator
            </Link>
          </div>
        </div>
      )}

      <Fix_navigation_bar />
      <div class="header-main-container">
            <Link to="/" class="logo-container">
            <LazyLoadImage
      alt="logo"
      style={{width:"100%",height:"100%"}}
      src={Visiting_Card_General_Format} // use normal <img> attributes as props
       />
            </Link>
            <div class="nav-item-container">
                <Link class="nav-item-container-item " to="/" >HOME</Link>
                <Link class="nav-item-container-item active" to="/product" >PRODUCT</Link>
                <Link class="nav-item-container-item" to="/solarcalculator" >CALCULATOR</Link>
               
               <Link class="nav-item-container-item" to="/contact" >CONTACT</Link>
              
            </div>
        </div>
         
      <div class="filter-container">
        <div className="input-box-container1">
       
          <Select onChange={(value)=> handleChange(value)} placeholder="Select Product" className="input-text1" id="projecttype" options={options} />
        </div>
      </div>
    
      <div tabIndex="0" id="Solar-Modules" class="product-container2">
        <div class="gallery2">
          <img
            alt="productimage"
            class="product-image2"
            src="https://images.unsplash.com/photo-1521618755572-156ae0cdd74d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=756&q=80"
          />
          <div style={{marginBottom:30,width:"100%",flexDirection:"row",justifyContent:"space-between",objectFit:"contain"}} >
            <LazyLoadImage
              alt="brand-logo"
              style={{ width: 100, height: 100,marginRight:20,objectFit:'contain' }}
              src={image} // use normal <img> attributes as props
            />
            <LazyLoadImage
              alt="brand-logo"
              style={{ width: 100, height: 100,marginRight:20,objectFit:'contain' }}
              src={image2} // use normal <img> attributes as props
            /><LazyLoadImage
            alt="brand-logo"
            style={{ width: 100, height: 100,marginRight:20,objectFit:'contain' }}
            src={image3} // use normal <img> attributes as props
          />
          </div>
         
        </div>

        <div class="product-brand-logo-and-description2">
        <div className="product-container-header" >
        Solar Modules
          </div>
         
          <div class="product-description-container2">
            <p>
            <ul>
              <li>
              Glass with anti-reflective coating improves transmission.
              </li>
              <li>
              Modules binned by current to improve system performance.
              </li>
              <li>
              Superior module efficiency as per international benchmarks.
              </li>
              <li>
              1500 VDC system voltage for lower BoS cost Salt mist, blowing sand and hail resistant.
              </li>
              <li>
              Sustain heavy wind & snow loads (2400 pa & 5400 pa).
              </li>
              <li>
              PID resistant with long term reliability.
              </li>
              <li>
              Specs – 40wp – 410wp
              </li>
              <li>
              Environmentally friendly energy.
              </li>
              <li>
              Reduce Energy Bills.
              </li>
              <li>
              25 Years Guarantee.
              </li>
              <li>
              Innovative Technology.
              </li>
              <li>
              Infinite Energy.
              </li>
          
            </ul>
            <Link className="enquiry-button-container2" to="/contact">
          ENQUIRY
          </Link>
         
            </p>
          </div>
          
          
        </div>
      </div>

      <div id="Solar-Grid-tie-Inverter" tabIndex="0"  class="product-container2">
       
        <div  class="product-brand-logo-and-description2">
          <div className="product-container-header" >
          Solar Grid-tie Inverter
          </div>
          
          <div class="product-description-container2">
         <p>
          <ul>
              <li>
              Wide MPPT Range.
              </li>
              <li>
              Maximum Efficiency 98.3%
              </li>
              <li>
              Multiple-monitoring Method (Wi-Fi/GPRS, RS485, WLAN).
              </li>
              <li>
              IP 65 dust-proof and water-proof.
              </li>
              <li>
              Anti- Islanding Protection Disconnects the inverter from grid during power failure.
              </li>
              <li>
              Fanless low noise design.
              </li>
              <li>
              Specs – 1kw – 100kw
              </li>
              <li>
              Minimum loss of electricity due to high efficiency.
              </li>
              <li>
              Stable and adequate supply of electricity.
              </li>
              <li>
              5+ Years warranties upto 25years expandable warranty schemes.
              </li>
              <li>
              Innovative Technology.
              </li>
              <li>
              Cleaner and Healthier Energy
              </li>
           
           </ul>
           <Link className="enquiry-button-container2" to="/contact">
          ENQUIRY
          </Link>
         
           </p>
          </div>
         
          
        </div>

        <div class="gallery2">
          <img
            alt="productimage"
            class="product-image2"
            src="https://images.unsplash.com/photo-1521618755572-156ae0cdd74d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=756&q=80"
          />
           <div style={{marginBottom:30,width:"100%",flexDirection:"row",justifyContent:"space-between",objectFit:"contain"}} >
            <LazyLoadImage
              alt="brand-logo"
              style={{ width: 100, height: 100,marginRight:20,objectFit:'contain' }}
              src={image} // use normal <img> attributes as props
            />
            <LazyLoadImage
              alt="brand-logo"
              style={{ width: 100, height: 100,marginRight:20,objectFit:'contain' }}
              src={image2} // use normal <img> attributes as props
            /><LazyLoadImage
            alt="brand-logo"
            style={{ width: 100, height: 100,marginRight:20,objectFit:'contain' }}
            src={image3} // use normal <img> attributes as props
          />
          </div>
        
        </div>

      </div>

      <div id="Solar-Off-grid-Inverters-(PCU’s)" tabIndex="0" class="product-container2">
        <div class="gallery2">
          <img
            alt="productimage"
            class="product-image2"
            src="https://images.unsplash.com/photo-1521618755572-156ae0cdd74d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=756&q=80"
          />
          <div style={{marginBottom:30,width:"100%",flexDirection:"row",justifyContent:"space-between",objectFit:"contain"}} >
            <LazyLoadImage
              alt="brand-logo"
              style={{ width: 100, height: 100,marginRight:20,objectFit:'contain' }}
              src={image} // use normal <img> attributes as props
            />
          </div>
          
        </div>

        <div class="product-brand-logo-and-description2">
        <div className="product-container-header" >
        Solar Off-grid Inverters
          </div>
        
          <div class="product-description-container2">
          
            <p>
            <ul>
              <li>
              Pure Sine Wave Output.
              </li>
              <li>
              Built-in MPPT Charge Controllers.
              </li>
              <li>
              Smart Overload, Short Circuit Sensing and other Built-in protections.
              </li>
              <li>
              Priority Selection for battery charging.
              </li>
              <li>
              Independent of Grid (Grid is not mandatory).
              </li>
              <li>
              LCD display to read and edit parameters.
              </li>
              <li>
              Specs – 1kw – 10kw
              </li>
              <li>
              Saves upto 90% of Grid Energy Usage.
              </li>
              <li>
              Reduce Energy Bills.
              </li>
              <li>
              Zero/Negligible Maintenance.
              </li>
              <li>
              Much Better performance as compared to normal UPS.
              </li>
              <li>
              Long Power Backup.
              </li>
          
            </ul>
            <Link className="enquiry-button-container2" to="/contact">
          ENQUIRY
          </Link>
       
            </p>
          </div>
           
        </div>
      </div>


      <div tabIndex="0" id="Solar-UPS" class="product-container2">
       
       <div class="product-brand-logo-and-description2">
       <div className="product-container-header" >
       Solar UPS
          </div>
      
         <div class="product-description-container2">
        <p>
         <ul>
             <li>
             Wide PWM/MPPT Range.
             </li>
             <li>
             Pure Sine Wave Output.
             </li>
             <li>
             Fast Charging algorithm.
             </li>
             <li>
             User defined settings.
             </li>
             <li>
             ECO and UPS mode available.
             </li>
             <li>
             ISOT (Intelligent Solar Optimization Technique) Technology.
             </li>
             <li>
             Specs – 350VA – 2035VA
             </li>
             <li>
             Minimizes Grid Usage Upto zero
             </li>
             <li>
             Stable and adequate supply of electricity.
             </li>
             <li>
             Supplies electricity during power cuts.
             </li>
             <li>
             Increases Battery Life, connected to the system.</li>
             <li>
             Multiple charging options available.
             </li>
          
          </ul>
          <Link className="enquiry-button-container2" to="/contact">
          ENQUIRY
          </Link>
        
          </p>
         </div>
           
       </div>

       <div class="gallery2">
         <img
           alt="productimage"
           class="product-image2"
           src="https://images.unsplash.com/photo-1521618755572-156ae0cdd74d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=756&q=80"
         />
            <div style={{marginBottom:30,width:"100%",flexDirection:"row",justifyContent:"space-between",objectFit:"contain"}} >
            <LazyLoadImage
              alt="brand-logo"
              style={{ width: 100, height: 100,marginRight:20,objectFit:'contain' }}
              src={image} // use normal <img> attributes as props
            />
            <LazyLoadImage
              alt="brand-logo"
              style={{width: 100, height: 100,marginRight:20,objectFit:'contain' }}
              src={image2} // use normal <img> attributes as props
            />
          </div>
      
       </div>

     </div>

     <div id="Solar-Battery" tabIndex="0" class="product-container2">
        <div class="gallery2">
          <img
            alt="productimage"
            class="product-image2"
            src="https://images.unsplash.com/photo-1521618755572-156ae0cdd74d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=756&q=80"
          />
             <div style={{marginBottom:30,width:"100%",flexDirection:"row",justifyContent:"space-between",objectFit:"contain"}} >
            <LazyLoadImage
              alt="brand-logo"
              style={{ width: 100, height: 100,marginRight:20,objectFit:'contain' }}
              src={image} // use normal <img> attributes as props
            />
            <LazyLoadImage
              alt="brand-logo"
              style={{ width: 100, height: 100,marginRight:20,objectFit:'contain' }}
              src={image2} // use normal <img> attributes as props
            />
          </div>
       
        </div>

        <div class="product-brand-logo-and-description2-for-solar-battery">
        <div className="product-container-header" >
        Solar Battery
          </div>
      
          <div class="product-description-container2">
            <p>
            <ul>
              <li>
              Tubular technology for better life.
              </li>
              <li>
              Rugged Performance.
              </li>
              <li>
              Very Low Maintenance.
              </li>
              <li>
              High Temperature Performance.
              </li>
              <li>
              Better Performance on High DOD.
              </li>
              <li>
              Upto 5 Years Warranty.
              </li>
              <li>
              Specs – 20Ah – 200Ah
              </li>
             
            </ul>
            <Link className="enquiry-button-container2" to="/contact">
          ENQUIRY
          </Link>
         
            </p>
          </div>
               
        </div>
      </div>

      <div id="Solar-On-Grid-System" tabIndex="0" class="product-container2">
       
       <div class="product-brand-logo-and-description2">
       <div className="product-container-header" >
       Solar On-Grid System
          </div>
      
         <div class="product-description-container2">
        <p>
         <ul>
             <li>
             Uses Sun Power to generate electricity from the unused Roof sapce.
             </li>
             <li>
             Maximum Efficiency upto 98.3%.
             </li>
             <li>
             Multiple-monitoring Method (Wi-Fi/GPRS, RS485, WLAN).
             </li>
             <li>
             More than 25years performance.
             </li>
             <li>
             Reduces Carbon footprints.
             </li>
             <li>
             Zero/Negligible Maintenance.
             </li>
             <li>
             Specs – As per requirement
             </li>
             <li>
             Minimum loss of electricity due to high efficiency.
             </li>
             <li>
             Can Sell Electricity to Discom.
             </li>
             <li>
             Reduces Electricity Bills
             </li>
             <li>
             Innovative Technology.
             </li>
             <li>
             Cleaner and Healthier Energy.
             </li>
          
          </ul>
          <Link className="enquiry-button-container2" to="/contact">
          ENQUIRY
          </Link>
         
          </p>
         </div>
          
       </div>

       <div class="gallery2">
         <img
           alt="productimage"
           class="product-image2"
           src="https://images.unsplash.com/photo-1521618755572-156ae0cdd74d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=756&q=80"
         />
       </div>

     </div>

     <div tabIndex="0" id="Solar-Off-grid-System" class="product-container2">
        <div class="gallery2">
          <img
            alt="productimage"
            class="product-image2"
            src="https://images.unsplash.com/photo-1521618755572-156ae0cdd74d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=756&q=80"
          />
        </div>

        <div class="product-brand-logo-and-description2">

          <div class="product-description-container2">
          <div className="product-container-header" >
          Solar Off-grid System
          </div>
     
            <p>
            <ul>
              <li>
              Glass with anti-reflective coating improves transmission.
              </li>
              <li>
              Modules binned by current to improve system performance.
              </li>
              <li>
              Superior module efficiency as per international benchmarks.
              </li>
              <li>
              1500 VDC system voltage for lower BoS cost Salt mist, blowing sand and hail resistant.
              </li>
              <li>
              Sustain heavy wind & snow loads (2400 pa & 5400 pa).
              </li>
              <li>
              PID resistant with long term reliability.
              </li>
              <li>
              Specs – As per requirement
              </li>
             <li>
             Zero Running Cost.
             </li>
             <li>
             Remote Operation and monitoring possible.
             </li>
             <li>
             Effective Power Management.
             </li>
             <li>
             It reduces the grid usage upto Zero.
             </li>
             <li>
             Absolutely Green Technology.
             </li>
            </ul>
            <Link className="enquiry-button-container2" to="/contact">
          ENQUIRY
          </Link>
         
            </p>
          </div>
          
        </div>
      </div>

      <div id="Solar-Water-Heater" tabIndex="0" class="product-container2">
       <div class="product-brand-logo-and-description2">
         
      
         <div class="product-description-container2">
         <div className="product-container-header" >
         Solar Water Heater
          </div>
      
        <p>
         <ul>
             <li>
             Attain 65deg – 85deg of Hot water
             </li>
             <li>
             Centralized system for all washrooms connected.
             </li>
             <li>
             Cost efficient as compared to other options available.
             </li>
             <li>
             Environment friendly.
             </li>
             <li>
             Can be used for Industrial purpose.
             </li>
             <li>
             Longer Life.
             </li>
             <li>
             Specs – 100Ltr – 500Ltr (or as per requirement)
             </li>
             <li>
             Minimum loss of electricity due to high efficiency.
             </li>
             <li>
             Stable and adequate supply of electricity.
             </li>
             <li>
             Very Low maintenance costs.

             </li>
             <li>
             Simple to construct and install.
             </li>
             <li>
             Saves time, as it gives continuous hot water supply.
             </li>
          
          </ul>
          <Link className="enquiry-button-container2" to="/contact">
          ENQUIRY
          </Link>
         
          </p>
         </div>
            
       </div>

       <div class="gallery2">
         <img
           alt="productimage"
           class="product-image2"
           src="https://images.unsplash.com/photo-1521618755572-156ae0cdd74d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=756&q=80"
         />
            <div style={{marginBottom:30,width:"100%",flexDirection:"row",justifyContent:"space-between",objectFit:"contain"}} >
            <LazyLoadImage
              alt="brand-logo"
              style={{ width: 100, height: 100,marginRight:20,objectFit:'contain' }}
              src={image3} // use normal <img> attributes as props
            />
          </div>
      
       </div>

     </div>


     <div id="Solar-Street-Light" tabIndex="0" class="product-container2">
        <div class="gallery2">
          <img
            alt="productimage"
            class="product-image2"
            src="https://images.unsplash.com/photo-1521618755572-156ae0cdd74d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=756&q=80"
          />
        </div>

        <div class="product-brand-logo-and-description2">
        
          <div class="product-description-container2">
          <div className="product-container-header" >
          Solar Street Light
          </div>
      
            <p>
            <ul>
              <li>
              Automatic dusk to dawn operations.
              </li>
              <li>
              Cable free operation.
              </li>
              <li>
              The variability of working hours.
              </li>
              <li>
              High Transmission of Lights.
              </li>
              <li>
              Intelligent Current Control.
              </li>
              <li>
              Start without Delay.
              </li>
              <li>
              Specs – 9Watts – 100Watts
              </li>
             <li>
             Tremendous Energy Saving.
             </li>
             <li>
             No pollution to Power Network.
             </li>
             <li>
             High Luminous Efficiency.
             </li>
             <li>
             Innovative Technology.
             </li>
             <li>
             Independent from the Utility Grid.
             </li>
            </ul>
            <Link className="enquiry-button-container2" to="/contact">
          ENQUIRY
          </Link>
        
            </p>
           
          </div>
          
        </div>
      </div>

      <div id="Solar-Water-Pump" tabIndex="0" class="product-container2">
       <div class="product-brand-logo-and-description2">
         
      
         <div class="product-description-container2">
         <div className="product-container-header" >
         Solar Water Pump
          </div>
      
        <p>
         <ul>
             <li>
             No Fuel Cost.
             </li>
             <li>
             No Electricity Required.
             </li>
             <li>
             Long Operating Life.
             </li>
             <li>
             Easy to Operate and Maintain.
             </li>
             <li>
             Eco-friendly.
             </li>
             <li>
             High Reliable and Durable.
             </li>
             <li>
             Specs – 1HP – 5HP
             </li>
             <li>
             Extremely Low Operating Cost.

             </li>
             <li>
             Comparatively Low Maintenance.
             </li>
             <li>
             Economically Beneficial.
             </li>
             <li>
           
             Productivity Increases in Times of Need.  </li>
             <li>
             Easy to Transport & Relocate.
             </li>
          
          </ul>
           <Link className="enquiry-button-container2" to="/contact">
          ENQUIRY
          </Link>
         
          </p>
         
         </div>
             
       </div>

       <div class="gallery2">
         <img
           alt="productimage"
           class="product-image2"
           src="https://images.unsplash.com/photo-1521618755572-156ae0cdd74d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=756&q=80"
         />
         <div style={{marginBottom:30,width:"100%",flexDirection:"row",justifyContent:"space-between",objectFit:"contain"}} >
            <LazyLoadImage
              alt="brand-logo"
              style={{  width: 100, height: 100,marginRight:20,objectFit:'contain' }}
              src={image3} // use normal <img> attributes as props
            />
          </div>
         
       </div>

     </div>


     <div id="Solar-Structure" tabIndex="0" class="product-container2">
        <div class="gallery2">
          <img
            alt="productimage"
            class="product-image2"
            src="https://images.unsplash.com/photo-1521618755572-156ae0cdd74d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=756&q=80"
          />
        </div>

        <div class="product-brand-logo-and-description2">
          <div class="product-description-container2">
          <div className="product-container-header" >
          Solar Structure
          </div>
      
            <p>
            <ul>
              <li>
              MS Fabricated
              </li>
              <li>
              Pre-galvanized
              </li>
              <li>
              Hot-Dip Galvanized
              </li>
              <li>
              Wide MPPT Range.
              </li>
              <li>
              Maximum Efficiency 98.3%.
              </li>
              <li>
              Multiple-monitoring Method (Wi-Fi/GPRS, RS485, WLAN).
              </li>
              <li>
              IP 65 dust-proof and water-proof.
              </li>
             <li>
             Anti- Islanding Protection Disconnects the inverter from grid during power failure.
             </li>
             <li>
             Fanless low noise design.
             </li>
             <li>
             Specs – 1kw – 100kw
             </li>
             <li>
             Minimum loss of electricity due to high efficiency.
             </li>
             <li>
             Stable and adequate supply of electricity.
             </li>
             <ll>
             5+ Years warranties upto 25years expandable warranty schemes.
             </ll>
             <li>
             Innovative Technology.
             </li>
             <li>
             Cleaner and Healthier Energy.
             </li>
            </ul>
            <Link className="enquiry-button-container2" to="/contact">
          ENQUIRY
          </Link>
        
            </p>
          </div>
          
        </div>
      </div>


      <div id="Solar-Distribution-Box" tabIndex="0" class="product-container2">
       <div class="product-brand-logo-and-description2">
         
      
         <div class="product-description-container2">
         <div className="product-container-header" >
         Solar Distribution Box
          </div>
      
        <p>
         <ul>
             <li>
             Wide MPPT Range.
             </li>
             <li>
             Maximum Efficiency 98.3%.
             </li>
             <li>
             Multiple-monitoring Method (Wi-Fi/GPRS, RS485, WLAN).
             </li>
             <li>
             IP 65 dust-proof and water-proof.
             </li>
             <li>
             Anti- Islanding Protection Disconnects the inverter from grid during power failure.
             </li>
             <li>
             Fanless low noise design.
             </li>
             <li>
             Specs – 1kw – 50kw
             </li>
             <li>
             Minimum loss of electricity due to high efficiency.

             </li>
             <li>
             Stable and adequate supply of electricity.
             </li>
             <li>
             5+ Years warranties upto 25years expandable warranty schemes.
             </li>
             <li>
             Innovative Technology.
              </li>
             <li>
             Cleaner and Healthier Energy.
             </li>
           
          </ul>
          <Link className="enquiry-button-container2" to="/contact">
          ENQUIRY
          </Link>
        
          </p>
         </div>
       </div>

       <div class="gallery2">
         <img
           alt="productimage"
           class="product-image2"
           src="https://images.unsplash.com/photo-1521618755572-156ae0cdd74d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=756&q=80"
         />
       </div>

     </div>


     <div id="Household-Wires" tabIndex="0" class="product-container2">
        <div class="gallery2">
          <img
            alt="productimage"
            class="product-image2"
            src="https://images.unsplash.com/photo-1521618755572-156ae0cdd74d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=756&q=80"
          />
            <div style={{marginBottom:30,width:"100%",flexDirection:"row",justifyContent:"space-between",objectFit:"contain"}} >
            <LazyLoadImage
              alt="brand-logo"
              style={{ width: 100, height: 100,marginRight:20,objectFit:'contain' }}
              src={image4} // use normal <img> attributes as props
            />
          </div>
        
        </div>

        <div class="product-brand-logo-and-description2">
        
          <div class="product-description-container2">
          <div className="product-container-header" >
          Household Wires
          </div>
      
            <p>
            <ul>
              <li>
              Wide MPPT Range.
              </li>
              <li>
              Maximum Efficiency 98.3%.
              </li>
              <li>
              Multiple-monitoring Method (Wi-Fi/GPRS, RS485, WLAN).
              </li>
              <li>
              IP 65 dust-proof and water-proof.
              </li>
              <li>
              Anti- Islanding Protection Disconnects the inverter from grid during power failure.
              </li>
              <li>
              Fanless low noise design.
              </li>
              <li>
              Specs – 1mm2 – 6mm2
              </li>
             <li>
             Minimum loss of electricity due to high efficiency.
             </li>
             <li>
             Stable and adequate supply of electricity.
             </li>
             <li>
             5+ Years warranties upto 25years expandable warranty schemes.
             </li>
             <li>
             Innovative Technology.
             </li>
             <li>
             Cleaner and Healthier Energy.
             </li>
            </ul>
            <Link className="enquiry-button-container2" to="/contact">
          ENQUIRY
          </Link>
         
            </p>
           
          </div>
          
        </div>
      </div>


      <Footer />
      <BottomFooter />
    </div>
  );
}

export default Product;
