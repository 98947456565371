import React,{useState,useEffect} from 'react'
import '../CSS/Contact.css'
import Fix_navigation_bar from '../ReusableComponent/Fix_navigation_bar'
import Header from '../ReusableComponent/Header'
import Footer from '../ReusableComponent/Footer'
import Select from 'react-select'
import Visiting_Card_General_Format from '../Assets/Visiting_Card_General_Format.png'
import {Link} from 'react-router-dom'
import '../CSS/Home.css'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ContactButton from '../ReusableComponent/ContactButton'
import CalculatorButton from '../ReusableComponent/CalculatorButton'
import BottomFooter from '../ReusableComponent/BottomFooter'
import image from '../Assets/luminous-logo.jpg'
var AWS = require("aws-sdk");
let awsConfig = {
    "region": "ap-south-1",
    "endpoint": "http://dynamodb.ap-south-1.amazonaws.com",
    "accessKeyId": "AKIA4BD4XVZYGRZLUMTN", "secretAccessKey": "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"
};
AWS.config.update(awsConfig);

let docClient = new AWS.DynamoDB.DocumentClient();


const options = [
    { value: 'Residential', label: 'Residential' },
    { value: 'Commercial', label: 'Commercial' },
  ]
  const options2 = [
    { value: 'Consultation', label: 'Consultation' },
    { value: 'Solar Panel', label: 'Solar Panel' },
    { value: 'Solar Wires', label: 'Solar Wires' },
    { value: 'Solar Batteries', label: 'Solar Batteries' },
    { value: 'Solar UPS', label: 'Solar UPS' },
    { value: 'Structure', label: 'Structure' },
    { value: 'ACDB Box', label: 'ACDB Box' },
    {value:'Installation',label:'Installation'},
    {value:'Repair',label:'Repair'},
    {value:'Other',label:'Other'},
    
    
  ]

function Contact() {
    const [menu,setMenu]=useState(false)
    const [name,setName]=useState(null)
    const [pincode,setPincode]=useState(null)
    const [mobile,setMobile]=useState(null)
    const [service,setService]=useState(null)
    const [email,setEmail]=useState(null)
    const [description,setDescription]=useState(null)
    const [projecttype,setProjectType]=useState(null)

    const handleChange = selectedOption => {
        setService( selectedOption );
        console.log(`Option selected:`, selectedOption);
    };
    
    const handleChangeProject = selectedOption => {
        setProjectType( selectedOption );
        console.log(`Option selected:`, selectedOption);
    };
 
    const save=()=>{
        var input = {
            "name":name,
            "email_id": email,
             "pincode": pincode,
              "mobile": mobile,
            "service": service,
             "description": description,
              "projecttype": projecttype
        };
        console.log(input)
        var params = {
            TableName: "users",
            Item:  input
        };
        docClient.put(params, function (err, data) {
    
            if (err) {
                console.log("users::save::error - " + JSON.stringify(err, null, 2));                      
            } else {
                console.log("users::save::success" );                      
            }
        });
    }
    
    const showMenuBar=()=>{
        setMenu(!menu)
    }
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  
    
  
    return (
        <div class="main-container" >
            <ContactButton/>
            <CalculatorButton/>
            {menu===false?
            <div className="menu-container" >
           <i  onClick={showMenuBar} class="material-icons menu1" >menu</i>
           <LazyLoadImage
      alt="sliderimage"
      style={{width:100,height:100}}
      src={Visiting_Card_General_Format} // use normal <img> attributes as props
       />
       <div className="menu-header" >ARM Solar Solution</div>
           </div>
           :
           <div className="siderbar" >
                <div className="siderbar-logo-container">
                <LazyLoadImage
      alt="sliderimage"
      style={{width:100,height:100}}
      src={Visiting_Card_General_Format} // use normal <img> attributes as props
       />
       <div className="menu-header1" >ARM Solar Solution</div>
       
       <div onClick={showMenuBar} className="siderbar-cancel-button" >
        X
       </div>
                </div>
                <div className="siderbar-item" >
                    <Link className="siderbar-item1" to="/" >
                    Home
                    </Link>
           
       </div>
       <div className="siderbar-item" >
           <Link className="siderbar-item1" to="/product" >
           Product
           </Link>
       </div>
        <div className="siderbar-item" >
           <Link className="siderbar-item1" to="/contact" >Contact</Link>
       </div>
        <div className="siderbar-item" >
           <Link className="siderbar-item1" to="/solarcalculator" >Calculator</Link>
       </div>

           </div>
            }
          
           <Fix_navigation_bar/>
           <div class="header-main-container">
            <Link to="/" class="logo-container">
            <LazyLoadImage
      alt="logo"
      style={{width:"100%",height:"100%"}}
      src={Visiting_Card_General_Format} // use normal <img> attributes as props
       />
            </Link>
            <div class="nav-item-container">
                <Link class="nav-item-container-item " to="/" >HOME</Link>
                <Link class="nav-item-container-item " to="/product" >PRODUCT</Link>
                <Link class="nav-item-container-item " to="/solarcalculator" >CALCULATOR</Link>
               
               <Link class="nav-item-container-item active" to="/contact" >CONTACT</Link>
                 
            </div>
        </div>
           <div class="image-container2" >
            <div class="contact-us-container1">
            <div className="contact-us-form-header1" >
               Contact Details
               </div>
          
            <div class="contact-us-form2" >
                   
          
            <div className="map-container" >
                            <div>
                                <strong>📧</strong><br/>
                            <b className="map-container-content" >
                            armsolarsolutions<br/>
                            @gmail.com
                            </b>

                            </div>
                            <div><strong>📞</strong><br/>
                            <b className="map-container-content">
                            +91-9993417435
                            </b>

                            </div>
                            
                    </div>
                    <div className="map-container2" >
                    <div><strong>🏢</strong><br/>
                            <b className="map-container-content">
                                Pune, India.
                            </b>

                            </div>
                            
                            <div><strong>🕑</strong><br/>
                            <b className="map-container-content">
                                12:00 - 12:00
                            </b>

                            </div>
                            
                    </div>
                    </div>
          
                
          
            </div>
            <div class="contact-us-container2">
                <div className="contact-us-form-header2" >Please submit your request for private space</div>
             
            <form class="contact-us-form" >
                    <div class="input-box-container" >
                <input onChange={(e)=>setName(e.target.value)} placeholder="Enter name"  className="input-text-for-name" type="text" id="fname" name="fname" ></input>
                <input maxLength="6" onChange={(e)=>setPincode(e.target.value)} placeholder="Enter pincode" className="input-text-for-pincode" type="number" id="pincode" name="pincode" ></input>
              
                    </div>
                    <div className="input-box-container" >
                <input maxLength="10" onChange={(e)=>setMobile(e.target.value)} className="input-text-for-mobile" placeholder="Enter Mobile No." type="number" id="mobilenumber" name="mobilenumber" ></input>
                <Select onChange={(value)=> handleChange(value)} placeholder="Select service" className="input-text-for-projecttype" id="service" options={options2} />
                  
                    </div>
                    <div className="input-box-container" >
                <input onChange={(e)=>setEmail(e.target.value)} placeholder="Enter email address" className="input-text-for-email" type="text" id="Email" name="Email" ></input>
                <Select onChange={(value)=>handleChangeProject(value)} placeholder="Select project-type" className="input-text-for-service" id="projecttype" options={options} />
                  
                    </div>

                   
                    
                    <div className="input-box-container" >
                        <textarea onChange={(e)=>setDescription(e.target.value)} placeholder="Description" className="description-text" id="description"  />
                    </div>

                    <div className="input-box-container  contact-submit-button-container" >
                        <div onClick={()=>save()} className="submit-button1" >
                        Submit
                        </div>
                      
                    </div>
                
                </form>    
                

            </div>
          
           
        </div>
        <div className="image-container3">
                <div class="rolling-solar-news1" >
           
                <iframe  src="https://maps.google.com/maps?q=18.5204,73.8567&hl=es&z=14&amp;output=embed" width="90%" height="450" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                </div>
   

        </div>
        <Footer/>
        <BottomFooter/>
       
        </div>
    )
}

export default Contact
