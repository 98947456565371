import React from 'react'
import '../CSS/fix_navigation_bar.css'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import icon1 from '../Assets/linkedin.png'
import icon2 from '../Assets/instagram.png'
import icon3 from '../Assets/facebook.png'
function Fix_navigation_bar() {
    return (
        <div class="container">
            <div class="header-of-contact" >+91-9993417435 | armsolarsolutions@gmail.com</div>

            <div class="icon-container" >
            <div>
            <LazyLoadImage
      alt="linkdin"
      style={{width:15,height:15,marginRight:12}}
      src={icon1} // use normal <img> attributes as props
       />
                
                </div>
                <a href="https://www.instagram.com/plugintothesun/">
                <LazyLoadImage
      alt="instagram"
      style={{width:15,height:15,marginRight:12}}
      src={icon2} // use normal <img> attributes as props
       />
                </a>
               
               
                <a href="https://www.facebook.com/armsolarsolutions">
                <LazyLoadImage
      alt="facebook"
      style={{width:15,height:15}}
      src={icon3} // use normal <img> attributes as props
       />
               </a>
                
            </div>
            <div className="border" >

            </div>
        </div>
    )
}

export default Fix_navigation_bar
