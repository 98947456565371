import React,{useState, useEffect, useRef } from 'react'
import Fix_navigation_bar from '../ReusableComponent/Fix_navigation_bar'
import Header from '../ReusableComponent/Header'
import '../CSS/Home.css'
import Footer from '../ReusableComponent/Footer'
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import image1 from '../Assets/luminous-logo.jpg'
import image2 from '../Assets/Okaya-Logo.png'
import image3 from '../Assets/waacab-logo-website.png'
import image4 from '../Assets/rahe-roshan-hamara-nation.png'
import image5 from '../Assets/tier_1.png'
import image6 from '../Assets/Waaree-logo.png'
import Select from 'react-select'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Visiting_Card_General_Format from '../Assets/Visiting_Card_General_Format.png'
import {Link} from 'react-router-dom'
import ContactButton from '../ReusableComponent/ContactButton'
import Calculator from './Calculator'
import CalculatorButton from '../ReusableComponent/CalculatorButton'
import BottomFooter from '../ReusableComponent/BottomFooter'
import slider1 from '../Assets/american-public-power-association-513dbrmj_5w-unsplash_optimized.jpeg'
import slider2 from '../Assets/hanson-lu-ksx63n5norg-unsplash_optimized.jpeg'
import silder3 from '../Assets/science-in-hd-zns6rizp9ru-unsplash_optimized.jpeg'
import org1 from '../Assets/mnre.jpg'
import org2 from '../Assets/msme.png'
import org3 from '../Assets/startupindia.png'
var AWS = require("aws-sdk");
let awsConfig = {
    "region": "ap-south-1",
    "endpoint": "http://dynamodb.ap-south-1.amazonaws.com",
    "accessKeyId": "AKIA4BD4XVZYGRZLUMTN", "secretAccessKey": "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"
};
AWS.config.update(awsConfig);

let docClient = new AWS.DynamoDB.DocumentClient();

const options = [
    { value: 'Residential', label: 'Residential' },
    { value: 'Commercial', label: 'Commercial' },
  ]
  const options2 = [
    { value: 'Consultation', label: 'Consultation' },
    { value: 'Solar Panel', label: 'Solar Panel' },
    { value: 'Solar Wires', label: 'Solar Wires' },
    { value: 'Solar Batteries', label: 'Solar Batteries' },
    { value: 'Solar UPS', label: 'Solar UPS' },
    { value: 'Structure', label: 'Structure' },
    { value: 'ACDB Box', label: 'ACDB Box' },
    {value:'Installation',label:'Installation'},
    {value:'Repair',label:'Repair'},
    {value:'Other',label:'Other'},
    
    
  ]

  const array=[
      slider1,
      slider2,
      silder3
  ]

  function useInterval(callback, delay) {
    const savedCallback = useRef();
  
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      let id = setInterval(() => {
        savedCallback.current();
      }, delay);
      return () => clearInterval(id);
    }, [delay]);
  }
  
function Home() {
    const [menu,setMenu]=useState(false)
    const [counter, setCounter] = useState(0);
    const [counter2, setCounter2] = useState(0);
    const [counter3, setCounter3] = useState(0);
    const [counter4, setCounter4] = useState(0);
    const [sliderIndex,setSliderIndex]=useState(0)
    const [name,setName]=useState(null)
    const [pincode,setPincode]=useState(null)
    const [mobile,setMobile]=useState(null)
    const [service,setService]=useState(null)
    const [email,setEmail]=useState(null)
    const [description,setDescription]=useState(null)
    const [projecttype,setProjectType]=useState(null)

    const handleChange = selectedOption => {
        setService( selectedOption );
        console.log(`Option selected:`, selectedOption);
    };
    
    const handleChangeProject = selectedOption => {
        setProjectType( selectedOption );
        console.log(`Option selected:`, selectedOption);
    };
 
    const save=()=>{
        var input = {
            "name":name,
            "email_id": email,
             "pincode": pincode,
              "mobile": mobile,
            "service": service,
             "description": description,
              "projecttype": projecttype
        };
        console.log(input)
        var params = {
            TableName: "users",
            Item:  input
        };
        docClient.put(params, function (err, data) {
    
            if (err) {
                console.log("users::save::error - " + JSON.stringify(err, null, 2));                      
            } else {
                console.log("users::save::success" );                      
            }
        });
    }
    

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  
    useInterval(() => {
        if(counter2<43){
            setCounter2(counter2+1)
        }
        if(counter3<3){
            setCounter3(counter3+1)
        }
        if(counter4<4){
            setCounter4(counter4+1)
        }
        
      }, 500);

      useInterval(() => {
        if(counter<1032){
        setCounter(counter + 1);
        }
        
      }, 10);


      useInterval(()=>{
        if(sliderIndex<2){
            setSliderIndex(sliderIndex+1)
        }else{
            setSliderIndex(0)
        }
       
      },1500)

      
      
    
    const showMenuBar=()=>{
        setMenu(!menu)
    }
    
    return (
        <div class="main-container">
            <ContactButton/>
            <CalculatorButton/>
            {menu===false?
            <div className="menu-container" >
           <i  onClick={showMenuBar} class="material-icons menu1" >menu</i>
           <LazyLoadImage
      alt="sliderimage"
      style={{width:100,height:100}}
      src={Visiting_Card_General_Format} // use normal <img> attributes as props
       />
       <div className="menu-header" >ARM Solar Solution</div>
           </div>
           :
           <div className="siderbar" >
                <div className="siderbar-logo-container">
                <LazyLoadImage
      alt="sliderimage"
      style={{width:100,height:100}}
      src={Visiting_Card_General_Format} // use normal <img> attributes as props
       />
       <div className="menu-header1" >ARM Solar Solution</div>
       
       <div onClick={showMenuBar} className="siderbar-cancel-button" >
        X
       </div>
                </div>
                <div className="siderbar-item" >
                    <Link className="siderbar-item1" to="/" >
                    Home
                    </Link>
           
       </div>
       <div className="siderbar-item" >
           <Link className="siderbar-item1" to="/product" >
           Product
           </Link>
       </div>
        <div className="siderbar-item" >
           <Link className="siderbar-item1" to="/contact" >Contact</Link>
       </div>
        <div className="siderbar-item" >
           <Link className="siderbar-item1" to="/solarcalculator" >Calculator</Link>
       </div>

           </div>
            }
           <Fix_navigation_bar/>
           <div class="header-main-container">
            <Link to="/" class="logo-container">
            <LazyLoadImage
      alt="logo"
      style={{width:"100%",height:"100%"}}
      src={Visiting_Card_General_Format} // use normal <img> attributes as props
       />
            </Link>
            <div class="nav-item-container">
                <Link class="nav-item-container-item active" to="/" >HOME</Link>
                <Link class="nav-item-container-item" to="/product" >PRODUCT</Link>
                <Link class="nav-item-container-item" to="/solarcalculator" >CALCULATOR</Link>
               
                <Link class="nav-item-container-item" to="/contact" >CONTACT</Link>
                
            </div>
        </div>
    
        <div class="image-container" >
            <div class="image-or-slider-container" >
            <LazyLoadImage
      alt="sliderimage"
      class="sliderimg"
      src={array[sliderIndex]} // use normal <img> attributes as props
       />
            </div>
            <div class="home-contact-us-container1">
                <div className="contact-form-container" >
                    
                <div className=" contact-form-container-paragraph" >
               Welcome to ARM Solar Solutions
               </div>
               <div className="home-contact-us-form-header" >
               Please submit your request for private space
               </div>
               
               <form class="contact-us-form" >
                    <div class="input-box-container" >
                <input onChange={(e)=>setName(e.target.value)} placeholder="Enter name"  className="input-text-for-name" type="text" id="fname" name="fname" ></input>
                <input maxLength="6" onChange={(e)=>setPincode(e.target.value)} placeholder="Enter pincode" className="input-text-for-pincode" type="number" id="pincode" name="pincode" ></input>
              
                    </div>
                    <div className="input-box-container" >
                <input maxLength="10" onChange={(e)=>setMobile(e.target.value)} className="input-text-for-mobile" placeholder="Enter mobile no." type="number" id="mobilenumber" name="mobilenumber" ></input>
                <Select onChange={(value)=> handleChange(value)} placeholder="Select service" className="input-text-for-projecttype" id="service" options={options2} />
                  
                    </div>
                    <div className="input-box-container" >
                <input onChange={(e)=>setEmail(e.target.value)}  placeholder="Enter email address" className="input-text-for-email" type="text" id="Email" name="Email" ></input>
                <Select onChange={(value)=>handleChangeProject(value)} placeholder="Select project-type" className="input-text-for-service" id="projecttype" options={options} />
                  
                    </div>

                   
                    
                    <div className="input-box-container" >
                        <textarea onChange={(e)=>setDescription(e.target.value)} placeholder="Description" className="description-text" id="description"  />
                    </div>

                  
                        <div onClick={()=> save()} className="submit-button" >
                            Submit
                        </div>

                  
                </form>    
         

                </div>
            
            </div>
        </div>
        <div class="partners-logo" >
            <strong>

         Partners 
            </strong>
            <div className="partners-logos-container" >
           <div className="panter-logo-subcontainer" >
           <LazyLoadImage
      alt="panter-logo"
      class="image-of-panters-logo"
      src={image1} // use normal <img> attributes as props
       />
           </div>
           <div className="panter-logo-subcontainer" >
           <LazyLoadImage
      alt="panter-logo"
      class="image-of-panters-logo-middle"
      src={image2} // use normal <img> attributes as props
       />
           </div>
            
        
          
         
                
             </div>

             <div className="partners-logos-container" >
           

            <div className="panter-logo-subcontainer" >
            <LazyLoadImage
      alt="panter-logo"
      class="image-of-panters-logo"
      src={image6} // use normal <img> attributes as props
       />
            </div>

            <div className="panter-logo-subcontainer" >
           <LazyLoadImage
      alt="panter-logo"
      class="image-of-panters-logo"
      src={image3} // use normal <img> attributes as props
       />
           </div>
           

          
             
          
             </div>
        </div>

        <div class="partners-logo" >
            <strong>

          Organizations
            </strong>
            <div className="partners-logos-container" >
           <div className="panter-logo-subcontainer" >
           <LazyLoadImage
      alt="panter-logo"
      class="image-of-organisation-logo"
      src={org1} // use normal <img> attributes as props
       />
           </div>
           <div className="panter-logo-subcontainer" >
           <LazyLoadImage
      alt="panter-logo"
      class="image-of-organisation-logo"
      src={org2} // use normal <img> attributes as props
       />
           </div>
              
        
          
         
                
             </div>

             <div className="partners-logos-container" >
            <div className="panter-logo-subcontainer" >
            <LazyLoadImage
      alt="panter-logo"
      class="image-of-panters-logo-of-rahe"
      src={org3} // use normal <img> attributes as props
       />
            </div>

            <div className="panter-logo-subcontainer" >
            <LazyLoadImage
      alt="panter-logo"
      class="image-of-panters-logo-of-tier"
      src={image5} // use normal <img> attributes as props
       />
            </div>
          

            
             
          
             </div>
        </div>
        
        <div  class="rolling-number-for-quick-fact-container" >
        <div className="title">
                        <strong>Quick Facts</strong>
                     </div>
                  
        <div  className="content-block">
                    <div className="quick-fact-container">
                        <div style={{fontSize:40}} >
                        🌳
                        </div>
                        <div className="number">{counter}</div>
                        <div className="sub-title">Trees Saved</div>
                    </div>
                    <div className="quick-fact-container">
                        <div style={{fontSize:40}} >
                        📁
                        </div>
                        <div className="number">{counter2} </div>
                        <div className="sub-title">Projects Completed</div>
                    </div>
                    <div className="quick-fact-container">
                    <div style={{fontSize:40}} >
                    ☁️
                        </div>
                       
                        <div className="number">{counter3} </div>
                        <div className="sub-title">Ton Co2 Saved</div>
                    </div>
                  
                    <div className="quick-fact-container">
                    <div style={{fontSize:40}} >
                    💡
                        </div>
                    
                        <div className="number">{counter4} </div>
                        <div className="sub-title">Installed</div>
                    </div>
                  
                  </div>

        </div>
        <div class="why-ARM-container" >
            <div class="why-ARM-subcontainer" >
                <div className="why-ARM-card-header" >
            ARM Solar Solutions
            </div>
            <div class="why-ARM-card-container" >
                <div class="why-ARM-card" >
                    <div className="why-ARM-card-icon" >
                    ☀️
                    </div>
                    <br/>
                    <div className="why-ARM-card-subcontainer-header" >

Modern Customized Solutions
                    </div>
                    <div>
                    Easy to handle
                    </div>
                    <p>
                    We provide our customers modern solutions shadow analytics 3D layout software based design we also provide custom systems to you so that you pay only for what you want.
                    </p>
                    <br/>
                    </div>
                <div class="why-ARM-card">
                <div className="why-ARM-card-icon" >
                🏆
                    </div>
                    <br/>
                    <div className="why-ARM-card-subcontainer-header" >
                    Best Quality and Price Assured
                    </div>
                    <div>
                    That's why we're known
                    </div>
                    <br/>
                    <p>
                    We provide tier-1 Solar modules with 25 years performance warranty at affordable rates we provide 97-99% efficiency solar inverter so you get maximum output for you solar system.
                    </p>
          
                </div>
                <div class="why-ARM-card" >
                <div className="why-ARM-card-icon" >
                📈
                    </div>
                    <br/>
                    <div className="why-ARM-card-subcontainer-header" >
          
Data Monitoring System
                    </div>
                     <div>
                    Track Everything
                    </div>
                    
                    <p>
                    We provide our customer real time data updates and monitor solar system generation regularly so if any diagnoses required we send our solar expert and solve them.

                    </p>
          
                </div>
                
            </div>
           
            </div>
           
         </div>
         <Footer/>
         <BottomFooter/>

       
        </div>
    )
}

export default Home
