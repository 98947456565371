import React from 'react'
import { Link } from 'react-router-dom'
import '../CSS/BottomFooter.css'
import Visiting_Card_General_Format from '../Assets/Visiting_Card_General_Format.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';

function BottomFooter() {
    return (
        <div className="bottom-main-container" >
            <div className="border1" >

            </div>
        <div className="bottom-container" >
           <div className="bottom-logo-container" >
           <div className="footer-image-container" >
           <LazyLoadImage
      alt="logo"
      className="bottom-logo"
      src={Visiting_Card_General_Format} // use normal <img> attributes as props
       />
       </div>
            <div className="arm-solar-solutions" >
                        ARM Solar Solutions
                    </div>
           </div>
            <Link to="/contact" className="bottom-container-contact-us-button" >
                Contact Us
            </Link>
        </div>
        </div>
    )
}

export default BottomFooter
