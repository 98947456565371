import React from 'react'
import {Route,Switch } from 'react-router-dom'
import Calculator from './Screen/Calculator'
import Contact from './Screen/Contact'
import Home from './Screen/Home'
import Product from './Screen/Product'
function App() {
  return (
    <div>
   <Switch>
     <Route exact path="/" component={Home} />
     <Route path="/product" component={Product}/>
     <Route  path="/contact" component={Contact} />
     <Route  path="/solarcalculator" component={Calculator} />
     
   </Switch>
   </div>
    )
}

export default App
