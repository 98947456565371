import React from 'react'
import '../CSS/ContactButton.css'
import {Link} from 'react-router-dom'

function ContactButton() {
    return (
        <Link to="/contact" className="contact-button-container" >
       <i class="material-icons contact-icon">call</i>
       <h5 className="contact-button-container-header" >Contact</h5>
        </Link>
    )
}

export default ContactButton
